import i18next from 'i18next';
import notFound from './not-found.json';

const i18n = i18next.createInstance(
  {
    fallbackLng: 'de',
    ns: ['errorPage'],
    defaultNS: 'errorPage',
    fallbackNS: 'errorPage',
    resources: {
      en: {
        errorPage: { 'not-found': notFound.en },
      },
      de: {
        errorPage: { 'not-found': notFound.de },
      },
    },
    interpolation: { escapeValue: false },
  },
  (err) => {
    if (err) return console.warn(err);
    return null;
  },
);

export default i18n;
