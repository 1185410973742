import { useTranslation } from 'react-i18next';
import { Button } from '@a-m-sales-solutions-gmbh/button';
import './NotFoundApp.scss';

function NotFoundApp() {
  const { t } = useTranslation();

  return (
    <div id="not-found">
      <div id="phone-call-img">
        <div className="not-found-text vh-100">
          <div className="not-found-text-headline mb-2 mt-n5">{t('errorPage:not-found.title')}</div>
          <div className="not-found-text-subline mb-5">{t('errorPage:not-found.subtitle')}</div>
          <a className="datenschutz link-secondary" href="https://www.umsatz.io/datenschutz">
            <Button className="mt-3 not-found-text-button"noShadow>
              {t('errorPage:not-found.goBack')}
            </Button>
          </a>
        </div>
      </div>
    </div>
  );
}

export default NotFoundApp;
